
.wedding-bg-img{
    position: relative;
   
    border: none;
    padding: 0;
}

.wedding-bg-img img {
    width: 100%;
}

.wedding-bg-img-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

.wedding-bg-img-text h2 {
    font-size: 3em;
    margin: 0;
    font-family: 'aclonica';
    font-weight: 300;
    color: #ffffff;
}

.wedding-bg-img p {
    font-size: 1.6em;
    margin: 10px 0;
    font-family: 'playfair dispaly',sans-serif;
    animation: fadeInUp 1.5s ease-in-out ;
}

.wedding-details {
    display: flex;
    align-items: center;
    padding: 20px;
    text-align: center;
}

.wedding-details:nth-child(2n+1) {
    flex-direction: row-reverse; /* Default: Image on the right, text on the left */
}

.wedding-details p {
    font-size: 1.2em; /* Adjust as needed */
    margin: 10px 20px;
    font-family: 'outfit';
    flex: 1;
    color: #6b6b6b;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.wedding-details img {
    width: auto; /* Adjust image width */
    height: 50vh; /* Adjust image height */
    flex: 1;
    margin: 10px;
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
@media (max-width: 768px) {

    .wedding-bg-img-text h2 {
        font-size: 1.5em; /* Adjust as needed */
    }
    
    .wedding-bg-img p {
        font-size: 1em; /* Adjust as needed */
    }
    .wedding-details {
        flex-direction: column; /* Stack on smaller screens */
    }

    .wedding-details:nth-child(2n+1),
    .wedding-details:nth-child(2n) {
        flex-direction: column-reverse; /* Image first, text second */
    }

    .wedding-details img {
        width: 80%; /* Full width for images */
        height: auto; /* Maintain aspect ratio */
        margin-bottom: 10px; /* Space between stacked items */
        border-radius: 4px;
    }

    .wedding-details p {
        margin: 10px 0; /* Adjust margin for stacked text */
        font-size: 14px;
    }
}
@media(max-width: 428px) {
    .wedding-bg-img-text h2 {
        font-size: 1em; /* Adjust as needed for smaller screens */
    }

    .wedding-bg-img p{
        font-size: 0.5em; /* Adjust as needed for smaller screens */
        
    }


 }
