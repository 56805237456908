.roomfirst {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.room-heading {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-family: 'outfit';
  font-weight: 600;
  margin: 10px 0;
}

.roomfirst-1 {
  flex: 1 1 calc(50% - 40px);
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  position: relative;
  display: inline-block;
  overflow: hidden; /* Ensure content does not overflow */
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);


}.roomfirst-1 h1{
  margin: 20px 0;
}


.roomfirst-1 img:hover {
  transform: scale(1.0); /* Slight zoom effect */
  filter: brightness(70%);
}

.roomfirst-1 img {
  transition: transform 0.3s ease;
  width: 100%;
  height: 60vh;
  display: block;
  border-radius: 8px;
  object-fit: cover;
  max-height: 500px; /* Adjust based on your design */
  object-position: center; /* Ensure the image is centered */
}

.roomicon {
  display: flex;
  justify-content: space-around;
 
}

.view-button {
  position: absolute;
  top:  40%; /* Adjust this value to position the button vertically */
  left: 90%;
  transform: translateX(-50%); /* Center the button horizontally */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: white;
  border: none;
  padding: 13px 30px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1; /* Ensure the button appears on top of the image */
  font-size: 16px;
}


.roomicon-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#bed {
  font-size: 50px;
  color: #6b6b6b;

}

.roomfirst-1 span {
  font-family: 'outfit';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.06em;
  line-height: 26px;
 
}

.roomicon-1-text {
  margin: 40px;
  text-align: center;
}

.roomicon-1-text span {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: #5b5b5b;
}

.price-tag {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 340px; /* Adjust as needed */
  left: 40px; /* Adjust as needed */
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}



.roomfirst-1 h1 {
  font-size: 25px;
  box-shadow: 2px 2px 5px rgba(47, 47, 47, 0.2);
  font-family: 'playfair display';
  color: black;
}

@media (max-width: 768px) {
  .roomfirst {
      gap: 10px;
      padding: 10px;
  }

  .roomfirst-1 {
      flex: 1 1 calc(50% - 20px);
  }

  .roomfirst-1 img {
      height: 40vh;
  }

  .roomfirst-1 h1 {
      font-size: 20px;
  }
  

  .price-tag {
      font-size: 26px;
      top: 200px;
      background-color: #009532;

     
  }

  .roomicon-1 span,
  .roomicon-1-text span {
      font-size: 14px;
      line-height: 2px;
      
  }

  .view-button {
    top: 50%; /* Adjust the button position for smaller screens */
    left: 80%; /* Slightly move the button closer to the center */
    font-size: 14px; /* Decrease font size */
    padding: 10px 20px; /* Adjust padding */
  }
  
}

@media (max-width: 428px) {
  .roomfirst {
      gap: 5px;
      padding: 5px;
  }

  .roomfirst-1 {
      flex: 1 1 calc(50% - 10px);
  }

  .roomfirst-1 img {
      height: 20vh;
  }

  .roomfirst-1 h1 {
      font-size: 18px;
     
  }

  .price-tag {
      font-size: 12px;
      top: 120px;
      left: 2px;
     padding: 3px;

  }
  .roomfirst-1 span{
   
    font-size: 8px;
    line-height: 10px;
   
  }
  .view-button {
    top: 30%; /* Adjust the button position for smaller screens */
    left: 80%; /* Slightly move the button closer to the center */
    font-size: 14px; /* Decrease font size */
    padding: 10px 20px; /* Adjust padding */
  }
  .roomicon-1-text{
    margin: 4px;
  }
  #bed{
    font-size: 20px;
    margin: 2px 0;
  
  }

  .roomicon-1 span,
  .roomicon-1-text span {
      font-size: 8px;
      line-height: 0;
      letter-spacing: 0;
  }
}
