/* Base navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  color: #fff;
  z-index: 1000;
  border: none;
  transition: background-color 0.8s ease-in-out;
}

.navbar.transparent {
  background: transparent;
}

.navbar.scrolled {
  background-color: #000000;
}

.nav-logo img {
  height: 66px;
  width: 66px;
}

.nav-menu li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 20px;
}

.nav-menu li a {
  color: white;
  text-decoration: none;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 50px;
  font-size: 20px;
  padding: 20px 0;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  transition: transform 0.5s ease-in-out;
}

/* Sidebar toggle button */
.sidebar-toggle {
  display: none;
  position: absolute;
  top: 28px;
  right: 30px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001; /* Ensure the toggle button is above other elements */
  background-color: transparent;
  color: white;
  border: none;
}

.sidebar-toggle:focus {
  outline: none;
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .sidebar-toggle {
    display: block; /* Show the toggle button */
  }

  .nav-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%; /* Full width */
    height: 100vh; /* Full height */
    background-color: #000; /* Background color when menu opens */
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center the menu items */
    gap: 20px; /* Adjust gap between items */
    transform: translateX(100%); /* Initially hide the menu */
    transition: transform 0.5s ease-in-out;
    z-index: 1000; /* Ensure the menu is above other content */
  }

  .nav-menu.open {
    transform: translateX(0); /* Show the menu when open */
  }

  .nav-logo img {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 50px;
    width: 50px;
  }

  .nav-menu li {
    font-size: 18px; /* Adjust font size for mobile */
  }
}

/* iPhone SE (small screens) */
@media (max-width: 375px) {
  .nav-menu li {
    font-size: 16px; /* Further reduce font size on small screens */
  }

  .nav-logo img {
    height: 40px;
    width: 40px;
  }

  .sidebar-toggle {
    font-size: 22px; /* Adjust the toggle button size */
  }
}
