.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden; /* Ensure the slider container hides overflow */
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  margin: auto;

}

.img-slider {
  display: flex;
  transition: transform 0.5s ease-in-out; /* Smooth transition for sliding */
}

.img-slider li {
  list-style: none;
  min-width: 25%; /* Each slide takes up 25% of the container width */
  box-sizing: border-box;
  padding: 0 10px;

}

.img-slider li img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 4px;
}
@media(max-width:768px){
  .img-slider li{
    padding: 0 6px;
  }
}
@media(max-width:412px){

  .img-slider li{
    padding: 0 6px;
  }
}
