/* Contact.css */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
    background-image: url('../assets//logo/contact_bg.webp');
    color: #333;
   
    
  }
  .contact_details{
    background-color: #ffffff;
    width: 90%;
    max-width: 600px;
    text-align: center;
    padding: 20px;
    margin: 10px 50px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: color 0.3s ease;
  }
  .contact_details a i:hover{
    color: rgb(77, 68, 197);
  }
   .map {
    background-color: #ffffff;
    padding: 20px;
    margin: 10px 50px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 600px;
    text-align: center;
  }
  
  .contact_details h2, .map h3 {
    color: #6b6b6b;
    margin-bottom: 15px;
    font-family: 'playfair display';
  }
  
  .contact_details p {
    font-size: 20px;
    margin: 20px 0;
    font-family: 'outfit';
    font-weight: 400;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: justify;
    
  }
  .contact_details p span{
    color: #6b6b6b;
  }
  .contact_details i{
    margin: 20px 20px;
    font-size: 30px;
    cursor: pointer;
    color: #6b6b6b;
  }

  
  .map iframe {
    width: 100%;
    height: 400px;
    border: none;
    border-radius: 8px;
  }
  
  @media (min-width: 768px) {
    .contact {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 14%;
    }
  
    .contact_details, .map {
      width: 45%;
    }
  }
  
@media (max-width: 428px) {
    .contact {
      padding-top: 18%;
    }
  
    .contact_details, .map {
      margin: 10px 20px;
    }
  
    .contact_details p {
      font-size: 18px;
    }
  
    .contact_details i {
      font-size: 25px;
    }
  
    .map iframe {
      height: 300px;
    }
  }
  
  @media (max-width: 360px) {
    .contact {
      padding-top: 22%;
    }
  
    .contact_details, .map {
      margin: 10px 10px;
    }
  
    .contact_details p {
      font-size: 16px;
    }
  
    .contact_details i {
      font-size: 20px;
    }
  
    .map iframe {
      height: 250px;
    }
  }