* {
  margin: 0;
  box-sizing: border-box; /* Add box-sizing for better layout control */
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px;
  background-color: rgb(138, 178, 148);
}

.about-details {
  margin: 20px 0;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  font-family: 'Outfit';
}

.about-details:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.about-details h1,
.about-details h2 {
  color: #2c3e50;
  margin-bottom: 15px;
}

.about-details h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.about-details h2 {
  font-size: 2em;
}

.about-details p {
  font-size: 1.2em;
  line-height: 1.8;
  color: #555;
}

.about-details span {
  padding: 30px 0px;
  line-height: 25px;
  color: black;
  display: flex;
  justify-content: center;
}

.about-image {
  width: 100%;
}

.about-image img {
  width: 100%;
  height: auto; /* Make height auto for better responsiveness */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .about {
      padding: 60px 10px;
  }

  .about-details {
      padding: 20px;
  }

  .about-details h1 {
      font-size: 1.8em;
  }

  .about-details h2 {
      font-size: 1.6em;
  }

  .about-details p {
      font-size: 1em;
  }
}

@media (max-width: 480px) {
  .about {
      padding: 40px 10px;
  }

  .about-details {
      margin: 10px 0;
      padding: 15px;
  }

  .about-details h1 {
      font-size: 1.5em;
  }

  .about-details h2 {
      font-size: 1.4em;
  }

  .about-details p {
      font-size: 0.9em;
  }

  .about-image img {
      height: auto; /* Ensure images maintain aspect ratio */
  }
}
