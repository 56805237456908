.swimming {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  padding: 40px 0; /* Add padding to top and bottom */
}

.img-pool {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Adjust max-width as needed */
  margin: 20px 0; /* Add some margin for better spacing */
}

.img-pool img {
  width: 100%;
  border-radius: 8px; /* Optional: add border radius for a nicer look */
}

.text-pool {
  padding: 20px 40px; /* Add padding around the text */
  text-align: center;
  font-size: 20px; /* Adjust font size as needed */
  font-family: 'Outfit';
  max-width: 100%; /* Prevent text from stretching too wide */
}

.text-pool h2 {
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
}

.text-pool p {
  font-size: 20px;
  color: #6b6b6b;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .img-pool {
    max-width: 100%; /* Adjust image container width for smaller screens */
    padding: 0 20px;
  }
  
  .text-pool {
    padding: 15px 20px; /* Reduce padding around the text */
    font-size: 18px; /* Adjust font size for smaller screens */
  }
  
  .text-pool h2 {
    font-size: 24px; /* Adjust heading size */
  }
  
  .text-pool p {
    font-size: 16px; /* Adjust paragraph font size */
  }
}

/* Media Query for even smaller screens */
@media (max-width: 480px) {
  .img-pool {
    max-width: 100%; /* Ensure images fit within the smaller screen */
  }
  
  .text-pool {
    padding: 10px 15px; /* Further reduce padding around the text */
    font-size: 16px; /* Further adjust font size for very small screens */
  }
  
  .text-pool h2 {
    font-size: 20px; /* Further adjust heading size */
  }
  
  .text-pool p {
    font-size: 14px; /* Further adjust paragraph font size */
  }
}
